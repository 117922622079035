// vuelidate (validation library)
import { withParams, validationMixin } from 'vuelidate'
import ja from './ja'
import jaDs2 from './jaDs2'

// constants
const ERROR_PARENT_CLASS_NAME = 'field'
const ERROR_CLASS_NAME = '-has-error'

export default {
  mixins: [validationMixin],
  mounted() {
    // VueインスタンスのオプションにbeforeUnloadAlert=trueでページ離脱時にアラート
    if (this.$options.beforeUnloadAlert) {
      //$(window).on('beforeunload', () => '投稿が完了していません。このまま移動しますか？');
    }
  },
  methods: {
    withParams,
    validate() {
      let target$v = this.$v
      target$v.$touch()
      Object.keys(target$v.$params).some(key => target$v[key].$touch())
      return !target$v.$invalid
    },
    doValidate(doesScroll = true) {
      this.$v.$reset()
      let hasError = false
      hasError = !this.validate()
      if (hasError && doesScroll)
        this.scrollToFirstError({ adjustHeight: -10, errorClassName: ERROR_CLASS_NAME })
      return !hasError
    },
    doResetValidate() {
      this.$v.$reset()
    },
    hasError(scope = null) {
      let target$v = scope ? this.$util.resolveObject(scope, this.$v) : this.$v
      if (!target$v) return false
      return target$v.$dirty && target$v.$invalid
    },
    errorClass(scope) {
      return { [ERROR_CLASS_NAME]: this.hasError(scope) }
    },
    scrollToFirstError({ adjustHeight = -10, errorClassName = ERROR_CLASS_NAME }) {
      this.$nextTick(() => {
        let $first = this.$el.querySelector(`.${errorClassName}`)
        if (!$first) return
        let $target = $first.classList.contains(ERROR_PARENT_CLASS_NAME)
          ? $first
          : $first.closest(`.${ERROR_PARENT_CLASS_NAME}`) || $first

        this.$scrollTo($target, 500, {
          offset: adjustHeight - this.$store.state.ui.fixedHeaderHeight
        })
      })
    },
    scrollToFormTop(adjustHeight = -20) {
      this.$scrollTo(this.$el, 500, {
        offset: adjustHeight - this.$store.state.ui.fixedHeaderHeight
      })
    },
    getErrorMessage(rulePath, field = null) {
      const $v = this.$util.resolveObject(rulePath, this.$v)
      const groupedRules = Object.keys($v.$params).filter(
        key => !$v.$params[key] && key !== '$each'
      )
      if (groupedRules.length) {
        let messages = groupedRules.map(rulePath => this.getErrorMessage(rulePath))
        return messages && messages[0]
      } else {
        let messages = Object.keys($v.$params)
          .filter(key => {
            return !$v[key] && $v.$dirty
          })
          .map(type => {
            return ja(type, $v.$params[type], field)
          })
        return messages && messages[0]
      }
    },
    doNotifySuccess(title) {
      this.$toast.open({
        type: 'success',
        message: title
      })
    },
    doNotifyError(grpcCode, message) {
      const title = this.$util.getNotifyErrorTitle(grpcCode, message)
      const text = this.$util.getNotifyErrorText(grpcCode, message)
      this.$notify({
        group: 'flash',
        type: 'error',
        title,
        text,
        max: 1
      })
    },
    /**
     * 新デザインシステム用定義
     * 増えすぎるようならばDS2用のform-mixinに切り分ける
     * TODO: リファクタは別途検討
     *
     * [description]
     * vuelidateを使ったバリデーションのエラー取得用関数
     * 主にDFieldで使用し、入力された値からエラーをリアルタイムで返す。
     *
     * [pattern]
     * 未入力状態 → infoで表示
     * 入力開始 → バリデーション開始、successかinfoで返す
     * submit押下後 → successかerrorで返す
     *
     * [param]
     * @param {Object} rulePath vuelidateのドット表記のプロパティアクセサー
     * @param {Boolean} hasSubmitted 初期サブミット状態の真偽値、falseが未submit
     * @param {string|null} field 表示用フィールド名
     *
     * [tips]
     * DFieldでsubmit前は表示/非表示を管理する、getErrorMessagesでは管理しない
     */
    getErrorMessages(rulePath, hasSubmitted = false, field = null) {
      const $v = this.$util.resolveObject(rulePath, this.$v)
      const validateType = Object.keys($v.$params)
      let messages = validateType.map(type => {
        const message = (() => {
          try {
            return jaDs2(type, $v.$params[type], field)
          } catch (err) {
            // NOTE: type不一致の場合はSentryに送信
            this.$sentry.captureException(err)
            return {}
          }
        })()

        if (!$v.$dirty) {
          return {
            type: 'info',
            message
          }
        }
        if (!hasSubmitted) {
          return {
            type: $v[type] ? 'success' : 'info',
            message
          }
        }
        return {
          type: $v[type] ? 'success' : 'error',
          message
        }
      })
      return messages
    },

    /**
     * 新デザインシステム用定義
     *
     * バリデーションチェックを行い、
     * エラーメッセージ（typeがerror）を一つだけ取り出す
     */
    getFirstErrorMessage(rulePath, hasSubmitted = false, field = null) {
      const errorMessageList = this.getErrorMessages(rulePath, hasSubmitted, field)
      const errors = errorMessageList.filter(v => v.type === 'error')
      if (errors.length > 0) {
        return [errors[0]]
      }
      return errorMessageList
    },

    /**
     * 新デザインシステム用定義
     *
     * 新デザインシステムで使用しているメッセージオブジェクトの形で
     * エラーメッセージを生成し返却する
     */
    genErrorMessage(errorMessage) {
      const message = {
        alwaysDisplayedMessage: errorMessage,
        validateMessage: errorMessage
      }
      return {
        type: 'error',
        message
      }
    }
  }
}
