const messages = {
  required: field => (field ? `${field}を入力してください` : '入力してください'),
  requiredIf: field => (field ? `${field}を入力してください` : '入力してください'),
  requiredUnless: field => (field ? `${field}を入力してください` : '入力してください'),
  minValue: (field, { min }) =>
    field ? `${field}は${min}以上で入力してください` : `${min}以上で入力してください`,
  maxValue: (field, { max }) =>
    field ? `${field}は${max}以下で入力してください` : `${max}以下で入力してください`,
  minYen: (field, { min }) =>
    field ? `${field}は${min}円以上で入力してください` : `${min}円以上で入力してください`,
  maxYen: (field, { max }) =>
    field ? `${field}は${max}円以下で入力してください` : `${max}円以下で入力してください`,
  minLength: (field, { min }) =>
    field ? `${field}は${min}文字以上で入力してください` : `${min}文字以上で入力してください`,
  maxLength: (field, { max }) =>
    field ? `${field}は${max}文字以内で入力してください` : `${max}文字以内で入力してください`,
  minNumLength: (field, { min }) =>
    field ? `${field}は${min}桁以上で入力してください` : `${min}桁以上で入力してください`,
  maxNumLength: (field, { max }) =>
    field ? `${field}は${max}桁以下で入力してください` : `${max}桁以内で入力してください`,
  minArrayLength: (field, { min }) =>
    field ? `${field}は${min}個以上必要です` : `${min}個以上必要です`,
  maxArrayLength: (field, { max }) =>
    field ? `${field}は${max}個以下にしてください` : `${max}個以下にしてください`,
  between: (field, { min, max }) =>
    field
      ? `${field}は${min}から${max}の間で入力してください`
      : `${min}から${max}の間で入力してください`,
  alpha: field => (field ? `${field}は半角英字で入力してください` : '半角英字で入力してください'),
  alphaNum: field =>
    field ? `${field}は半角英数字で入力してください` : '半角英数字で入力してください',
  numeric: field => (field ? `${field}は数字で入力してください` : '数字で入力してください'),
  email: field =>
    field ? `${field}の形式が正しくありません` : 'メールアドレスの形式が正しくありません',
  regex: field => (field ? `${field}の形式が正しくありません` : '形式が正しくありません'),
  phoneNumber: field => (field ? `${field}の形式が正しくありません` : '電話番号が正しくありません'),
  sameAs: field => (field ? `${field}が一致しません` : '一致しません'),
  date: field =>
    field ? `${field}に未来を選択することはできません` : '未来を選択することはできません',
  emoji: field =>
    field ? `${field}に絵文字を入力することはできません` : '絵文字を入力することはできません',
  emailDomain: field => (field ? `${field}のドメインが違います` : 'ドメインが違います'),
  luhnChecksum: field =>
    field ? `正しい${field}を入力してください` : '正しいカード番号を入力してください',
  expire: field => (field ? `${field}の年月が過ぎています` : '年月が過ぎています'),
  char4Byte: field =>
    field
      ? `${field}に絵文字・特殊文字を入力することはできません`
      : '絵文字・特殊文字を入力することはできません'
}

export default (type, param, field = null) => {
  return messages[type] ? messages[type](field, param) : null
}
