type validateMessageTypeParams = {
  min?: string | number
  max?: string | number
  length?: string | number
  domainName?: string
  field?: string
  title?: string
  message?: string
  numericalUnit?: NumericalUnit
  alertType?: string
}

type validateMessageGetter = (params: validateMessageTypeParams) => string

type validateMessageType = {
  required: validateMessageGetter
  requiredChoice: validateMessageGetter
  minValue: validateMessageGetter
  maxValue: validateMessageGetter
  minLength: validateMessageGetter
  maxLength: validateMessageGetter
  maxNumLength: validateMessageGetter
  maxArrayLength: validateMessageGetter
  anyMaxLength: validateMessageGetter
  between: validateMessageGetter
  anyBetween: validateMessageGetter
  anyJustLength: validateMessageGetter
  alphaNum: validateMessageGetter
  alphaNumMix: validateMessageGetter
  justNumericLength: validateMessageGetter
  numeric: validateMessageGetter
  fullWidthCharacter: validateMessageGetter
  alphaNumSymbol: validateMessageGetter
  email: validateMessageGetter
  emoji: validateMessageGetter
  emailDomain: validateMessageGetter
  char4Byte: validateMessageGetter
  noEmojiAndChar4Byte: validateMessageGetter
  katakana: validateMessageGetter
  noSymbolName: validateMessageGetter
  noSymbolUserName: validateMessageGetter
  noForbiddenWordUserName: validateMessageGetter
  noSpace: validateMessageGetter
  noOnlyBlankSpaces: validateMessageGetter
  noOnlyBlankSpacesAllowEmpty: validateMessageGetter
  url: validateMessageGetter
  isParentalConsent: validateMessageGetter
  isPast: validateMessageGetter
  phoneNumber: validateMessageGetter
  phoneNumberRegister: validateMessageGetter
  smsPhoneNumber: validateMessageGetter
  sameAs: validateMessageGetter
  luhnChecksum: validateMessageGetter
  expire: validateMessageGetter
  requiredChange: validateMessageGetter
  availableCardBrand: validateMessageGetter
  betweenYen: validateMessageGetter
  betweenYenWithNumericalUnit: validateMessageGetter
  urlString: validateMessageGetter
  notIncludeEmail: validateMessageGetter
  notIncludeTel: validateMessageGetter
  youtubeUrl: validateMessageGetter
  isRegistered: validateMessageGetter
  customValidation: validateMessageGetter
}

const NUMERICAL_UNIT = {
  tenThousand: {
    value: 10000,
    displayValue: '万'
  }
} as const
const numericalUnitDisplayValue = Object.values(NUMERICAL_UNIT).map(v => v.displayValue)
type NumericalUnit = (typeof numericalUnitDisplayValue)[number]

/*
 常に表示されているメッセージ
 ex.
  ✔︎ 入力必須
  × 入力必須
*/
const alwaysDisplayedMessages: validateMessageType = {
  required: () => '入力必須',
  requiredChoice: () => '選択必須',
  minValue: ({ min }) => `${min}以上`,
  maxValue: ({ max }) => `${max}以下`,
  minLength: ({ min }) => `${min}文字以上`,
  maxLength: ({ max }) => `${max}文字以下`,
  maxNumLength: ({ max }) => `${max}桁以下`,
  maxArrayLength: ({ max, field }) =>
    field ? `${field}は${max}個以下にしてください` : `${max}個以下にしてください`,
  // 0文字許容
  anyMaxLength: ({ max }) => `${max}文字以下`,
  between: ({ min, max }) => `${min}文字以上${max}文字以下`,
  // 0文字許容
  anyBetween: ({ min, max }) => `${min}文字以上${max}文字以下`,
  anyJustLength: ({ length }) => `${length}文字`,
  alphaNum: () => '半角英数字',
  alphaNumMix: () => '英数混合必須',
  justNumericLength: ({ length, alertType }) => {
    return alertType === 'isNoHyphen'
      ? `ハイフンなしの半角数字${length}桁で入力してください`
      : `${length}桁の半角数字で入力してください`
  },
  numeric: () => '数値のみ',
  fullWidthCharacter: () => '全角のみ',
  alphaNumSymbol: () => '半角英数記号のみ',
  // TODO:メールアドレスの文言は相談
  email: () => 'メールアドレス以外の形式不可',
  emoji: () => '絵文字不可',
  // @ts-ignore TS2322
  emailDomain: ({ domainName }) => domainName,
  char4Byte: () => '絵文字不可',
  noEmojiAndChar4Byte: () => '絵文字不可',
  katakana: () => 'カタカナのみ',
  noSymbolName: () => '使用できない文字が含まれています',
  noSymbolUserName: () => '記号不可',
  noForbiddenWordUserName: () => '「coconala」「ココナラ」「ここなら」は使用できません',
  noSpace: () => '空白文字は入力できません',
  noOnlyBlankSpaces: () => '空白文字以外の文字も利用してください',
  noOnlyBlankSpacesAllowEmpty: () => '空白文字以外の文字も利用してください',
  url: () => 'URL以外の形式不可',
  isParentalConsent: () => '親権者の同意が必要なため入力してください',
  isPast: () => '正しい日付を選択してください',
  phoneNumber: () => '正しい番号を入力してください',
  phoneNumberRegister: () => 'ハイフンなしの半角数字10~11桁で入力してください',
  smsPhoneNumber: () =>
    'SMS（ショートメッセージサービス）が利用できる日本国内の携帯電話番号を入力してください',
  sameAs: ({ field }) => (field ? `${field}が一致しません` : '一致しません'),
  luhnChecksum: ({ field }) =>
    field ? `正しい${field}を入力してください` : '正しいカード番号を入力してください',
  expire: ({ field }) => (field ? `${field}の年月が過ぎています` : '年月が過ぎています'),
  requiredChange: ({ field }) => (field ? `${field}を変更してください` : '変更してください'),
  availableCardBrand: () => 'この出品者へのお支払いではこのカードをご利用いただけません',
  betweenYen: ({ min, max }) => `${min}円〜${max}円`,
  betweenYenWithNumericalUnit: ({ min, max, numericalUnit }) => {
    // メッセージ表示時には、数値単位で割り戻す
    switch (numericalUnit) {
      case NUMERICAL_UNIT.tenThousand.displayValue:
        return `${Number(min) / NUMERICAL_UNIT.tenThousand.value}${numericalUnit}円〜${
          Number(max) / NUMERICAL_UNIT.tenThousand.value
        }${numericalUnit}円`
      default:
        return `${min}円〜${max}円`
    }
  },
  betweenNum: ({ min, max, suffix }) => `${min}${suffix}〜${max}${suffix}`,
  urlString: () => 'http または httpsから始まるURL形式で入力してください',
  notIncludeEmail: () => 'メールアドレスを含めることはできません',
  notIncludeTel: () => '電話番号を含めることはできません',
  youtubeUrl: ({ field }) =>
    field ? `${field}の形式が正しくありません` : '形式が正しくありません',
  isRegistered: ({ title }) => `この${title}は登録済みです`,
  // @ts-ignore TS2322
  customValidation: ({ message }) => message
}

/*
 バリデーション発火時に表示されるのみにメッセージ
*/
const validateMessages: validateMessageType = {
  required: ({ field }) => (field ? `${field}を入力してください` : '入力してください'),
  requiredChoice: ({ field }) => (field ? `${field}を選択してください` : '選択してください'),
  minValue: ({ min, field }) =>
    field ? `${field}は${min}以上で入力してください` : `${min}以上で入力してください`,
  maxValue: ({ max, field }) =>
    field ? `${field}は${max}以下で入力してください` : `${max}以下で入力してください`,
  minLength: ({ min, field }) =>
    field ? `${field}は${min}文字以上で入力してください` : `${min}文字以上で入力してください`,
  maxLength: ({ max, field }) =>
    field ? `${field}は${max}文字以下で入力してください` : `${max}文字以下で入力してください`,
  maxNumLength: ({ max, field }) =>
    field ? `${field}は${max}桁以下で入力してください` : `${max}桁以下で入力してください`,
  maxArrayLength: ({ max, field }) =>
    field ? `${field}は${max}個以下にしてください` : `${max}個以下にしてください`,
  // 0文字許容
  anyMaxLength: ({ max }) => `${max}文字以下で入力してください`,
  between: ({ min, max }) => `${min}文字以上${max}文字以下で入力してください`,
  // 0文字許容
  anyBetween: ({ min, max }) => `${min}文字以上${max}文字以下で入力してください`,
  anyJustLength: ({ length }) => `${length}桁の番号を入力してください`,
  alphaNum: () => '半角英数字で入力してください',
  alphaNumMix: () => '英字・数字それぞれ含めて入力してください',
  justNumericLength: ({ length, alertType }) => {
    return alertType === 'isNoHyphen'
      ? `ハイフンなしの半角数字${length}桁で入力してください`
      : `${length}桁の半角数字で入力してください`
  },
  numeric: ({ field }) =>
    field ? `${field}は数字のみで入力してください` : '数字のみで入力してください',
  fullWidthCharacter: () => '全角のみ入力可能です',
  alphaNumSymbol: () => '半角英数字と記号のみ入力可能です',
  email: () => '正しいメールアドレス形式で入力してください',
  emoji: () => '絵文字は入力できません',
  // @ts-ignore TS2322
  emailDomain: ({ domainName }) => domainName,
  char4Byte: () => '絵文字は入力できません',
  noEmojiAndChar4Byte: () => '絵文字は入力できません',
  katakana: () => 'カタカナで入力してください',
  noSymbolName: () => '使用できない文字が含まれています',
  noSymbolUserName: () => '記号は入力できません',
  noForbiddenWordUserName: () => '「coconala」「ココナラ」「ここなら」は使用できません',
  noSpace: () => '空白文字は入力できません',
  noOnlyBlankSpaces: () => '空白文字以外の文字も利用してください',
  noOnlyBlankSpacesAllowEmpty: () => '空白文字以外の文字も利用してください',
  url: () => 'URLを入力してください',
  isParentalConsent: () => '親権者の同意が必要なため入力してください',
  isPast: () => '正しい日付を選択してください',
  phoneNumber: () => '正しい番号を入力してください',
  phoneNumberRegister: () => 'ハイフンなしの半角数字10~11桁で入力してください',
  smsPhoneNumber: () =>
    'SMS（ショートメッセージサービス）が利用できる日本国内の携帯電話番号を入力してください',
  sameAs: ({ field }) => (field ? `${field}が一致しません` : '一致しません'),
  luhnChecksum: ({ field }) =>
    field ? `正しい${field}を入力してください` : '正しいカード番号を入力してください',
  expire: ({ field }) => (field ? `${field}の年月が過ぎています` : '年月が過ぎています'),
  requiredChange: ({ field }) => (field ? `${field}を変更してください` : '変更してください'),
  availableCardBrand: () => 'この出品者へのお支払いではこのカードをご利用いただけません',
  betweenYen: ({ min, max, field }) => {
    const fieldLabel: string = field ? `${field}は` : ''
    // @ts-ignore TS2532
    return `${fieldLabel}${min.toLocaleString()}円〜${max.toLocaleString()}円の範囲で入力してください`
  },
  betweenYenWithNumericalUnit: ({ min, max, numericalUnit, field }) => {
    const fieldLabel: string = field ? `${field}は` : ''

    // メッセージ表示時には、数値単位で割り戻す
    switch (numericalUnit) {
      case NUMERICAL_UNIT.tenThousand.displayValue:
        return `${fieldLabel}${(
          Number(min) / NUMERICAL_UNIT.tenThousand.value
        ).toLocaleString()}${numericalUnit}円〜${(
          Number(max) / NUMERICAL_UNIT.tenThousand.value
        ).toLocaleString()}${numericalUnit}円の範囲で入力してください`
      default:
        return `${fieldLabel}${Number(min).toLocaleString()}${numericalUnit}円〜${Number(
          max
        ).toLocaleString()}${numericalUnit}円の範囲で入力してください`
    }
  },
  betweenNum: ({ min, max, suffix, field }) => {
    const fieldLabel: string = field ? `${field}は` : ''
    // @ts-ignore TS2532
    return `${fieldLabel}${min.toLocaleString()}${suffix}〜${max.toLocaleString()}${suffix}の範囲で入力してください`
  },

  urlString: () => 'http または httpsから始まるURL形式で入力してください',
  notIncludeEmail: () => 'メールアドレスを含めることはできません',
  notIncludeTel: () => '電話番号を含めることはできません',
  youtubeUrl: ({ field }) =>
    field ? `${field}の形式が正しくありません` : '形式が正しくありません',
  isRegistered: ({ title }) => `この${title}は登録済みです`,
  // @ts-ignore TS2322
  customValidation: ({ message }) => message
}

export type ValidateMessage = {
  alwaysDisplayedMessage: string
  validateMessage: string
}

export default (
  type: keyof validateMessageType,
  params: validateMessageTypeParams,
  field?: string
): ValidateMessage => {
  if (!(alwaysDisplayedMessages[type] && validateMessages[type])) {
    // js、vueからの呼び出し可能性もあるので、存在チェック
    throw new Error(`validate_message_type:${type} is not a function`)
  }

  const mergedParams = field ? { ...params, field } : params
  return {
    alwaysDisplayedMessage: alwaysDisplayedMessages[type](mergedParams),
    validateMessage: validateMessages[type](mergedParams)
  }
}

export type ValidateMessageObjectType = {
  type: string
  message: {
    alwaysDisplayedMessage: string
    validateMessage: string
  }
}
